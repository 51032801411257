import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertController, MenuController, ModalController } from '@ionic/angular'
import { VastaRX } from '@vasta/rx'
import * as moment from 'moment'
import { NovoFiltro } from 'src/app/components/vasta-tabela/vasta-tabela'
import { ImportacaoService } from 'src/app/services/importacao.service'
import { PermissoesUsuarioService } from 'src/app/services/permissoes-usuario.service'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { IAnimalPayload } from 'src/app/utils/interfaces/animais.interface'
import { Pelagens, Racas } from 'src/app/utils/racas'
import { ModalListaAnimaisPage } from '../bovinos/modal-lista-animais/modal-lista-animais.page'
import { ModalListaLotesPage } from '../bovinos/modal-lista-lotes/modal-lista-lotes.page'

declare const jexcel

@Component({
  selector: 'app-importacao',
  templateUrl: './importacao.page.html',
  styleUrls: ['./importacao.page.scss']
})
export class ImportacaoPage implements OnInit, AfterViewInit {
  @ViewChild('spreadsheet') spreadsheet: ElementRef

  ativarComposicao = false

  public colunas = {
    status: { title: 'Status', type: 'hidden', width: '170px' },
    hash: { title: 'Hash', type: 'hidden' },
    nome: { title: 'Nome', type: 'text', width: '110px' },
    nbrinco: { title: 'Brinco Eletrônico', type: 'text', width: '140px' },
    numeracao: { title: 'Numeração', type: 'text', width: '170px' },
    sexo: { title: 'Sexo', type: 'dropdown', width: '90px', source: ['', 'Macho', 'Fêmea'] },
    raca: {
      title: 'Raça',
      type: 'dropdown',
      width: '140px',
      autocomplete: true,
      source: ['', ...Object.values(Racas)]
    },
    meta_raca_composicao: {
      title: 'Composição',
      type: 'dropdown',
      width: '140px',
      autocomplete: true,
      source: ['', '1/2', '2/3', '1/4', '3/4', '1/8', '2/8', '3/8', '5/8']
    },
    meta_raca_secundaria: {
      title: 'Raça 2',
      type: 'dropdown',
      width: '140px',
      autocomplete: true,
      source: ['', ...Object.values(Racas)]
    },
    meta_raca_secundaria_composicao: {
      title: 'Composição',
      type: 'dropdown',
      width: '140px',
      autocomplete: true,
      source: ['', '1/2', '2/3', '1/4', '3/4', '1/8', '2/8', '3/8', '5/8']
    },
    meta_raca_terciaria: {
      title: 'Raça 3',
      type: 'dropdown',
      width: '140px',
      autocomplete: true,
      source: ['', ...Object.values(Racas)]
    },
    meta_raca_terciaria_composicao: {
      title: 'Composição',
      type: 'dropdown',
      width: '140px',
      autocomplete: true,
      source: ['', '1/2', '2/3', '1/4', '3/4', '1/8', '2/8', '3/8', '5/8']
    },
    data_nascimento: {
      title: 'Data de Nascimento',
      type: 'calendar',
      options: { format: 'DD/MM/YYYY' },
      width: '160px'
    },
    idade_em_meses: {
      title: 'Idade em Meses',
      type: 'numeric',
      width: '160px',
      mask: '#.##',
      decimal: ','
    },
    peso_ao_nascer: {
      title: 'Peso ao nascer (Kg)',
      type: 'numeric',
      width: '160px',
      mask: '#.##,00 kg',
      decimal: ','
    },
    lote: { title: 'Lote', type: 'html', width: '160px' },
    data_desmame: { title: 'Data do desmame', type: 'calendar', width: '160px' },
    peso_no_desmame: {
      title: 'Peso no desmame (Kg)',
      type: 'numeric',
      width: '180px',
      mask: '#.##,00 kg',
      decimal: ','
    },
    peso_atual: { title: 'Peso atual (Kg)', type: 'numeric', width: '160px', mask: '#.##,00 kg', decimal: ',' },
    peso_1_data: { title: 'Data da pesagem 1', type: 'calendar', width: '160px' },
    peso_1: { title: 'Peso 1 (Kg)', type: 'numeric', width: '140px', mask: '#.##,00 kg', decimal: ',' },
    peso_2_data: { title: 'Data da pesagem 2', type: 'calendar', width: '160px' },
    peso_2: { title: 'Peso 2 (Kg)', type: 'numeric', width: '140px', mask: '#.##,00 kg', decimal: ',' },
    observacao: { title: 'Observações', type: 'text', width: '160px' },
    partos: { title: 'Qtd de Partos', type: 'text', width: '140px' },
    // tipo_entrada: {
    //   title: 'Tipo de aquisição',
    //   type: 'dropdown',
    //   width: '140px',
    //   source: ['Nascido na Fazenda', 'Comprado']
    // },
    // valor_compra: { title: 'Valor da compra', type: 'numeric', width: '140px', mask: 'R$ #.##,00', decimal: ',' },
    // data_aquisicao: { title: 'Data da compra', type: 'calendar', width: '140px' },
    pai: { title: 'Pai', type: 'html', width: '100px' },
    mae: { title: 'Mãe', type: 'html', width: '100px' },
    meta_pelagem: {
      title: 'Pelagem',
      type: 'dropdown',
      width: '170px',
      autocomplete: true,
      source: ['', ...Object.values(Pelagens)]
    },
    meta_registroDefinitivo: { title: 'Registro Definitivo', type: 'text', width: '170px' },
    meta_dataRegistroDefinitivo: { title: 'Data Reg. Definitivo', type: 'calendar', width: '170px' },
    meta_registroDeNascimento: { title: 'Registro de Nascimento', type: 'text', width: '190px' },
    meta_dataRegistroDeNascimento: { title: 'Data Reg. Nascimento', type: 'calendar', width: '180px' },
  }

  public tabela
  public dadosASalvar: unknown
  public colunasArray = []
  public timeoutValidacao = null
  public adicionarMais = 10
  public Racas = Racas
  public numeracaoDeAnimaisExistentes: string[]

  public loteProp: { hash: string; nome: string }

  public isModal = false
  public vagasDeAnimaisDisponiveis: number
  private totalDeLinhasAdicionadas = 0

  constructor(
    private utilsCtrl: IonicUtilsService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private importacaoCtrl: ImportacaoService,
    private propriedadesCtrl: PropriedadesService,
    private router: Router,
    private menuCtrl: MenuController,
    private permissoesUsuarioCtrl: PermissoesUsuarioService
  ) {
    this.menuCtrl.enable(false)
    this.colunasArray = Object.keys(this.colunas)
    this.vagasDeAnimaisDisponiveis = this.permissoesUsuarioCtrl?.informacoesDoPlanoDoUsuarioNaPropriedadeAtual?.vagasDisponiveis
  }

  async ngOnInit(): Promise<void> {
    await this.sairDaPaginaSeLimiteDeAnimaisAlcancado()

    if (!this.isModal) {
      const state = this.router.getCurrentNavigation()?.extras?.state

      if (state) {
        this.loteProp = state['loteProp']
      }
    }

    const [numeracaoDeAnimaisExistentes] = await this.importacaoCtrl.getNumeracoesExistentes()
    this.numeracaoDeAnimaisExistentes = numeracaoDeAnimaisExistentes
  }

  async ngOnDestroy(): Promise<void> {
    this.menuCtrl.enable(true)
  }

  ngAfterViewInit(): void {
    this.initTabela()
    this.atualizarLimiteDeLinhas()
  }

  async sairDaPaginaSeLimiteDeAnimaisAlcancado(): Promise<void> {
    const { isPossuiPermissaoCadastro } = await this.permissoesUsuarioCtrl.checkPermissoesCadastroNovosAnimais()

    if(!isPossuiPermissaoCadastro && this.isModal) {
      await this.modalCtrl.dismiss()
    }

    if(!isPossuiPermissaoCadastro && !this.isModal) {
      await this.router.navigateByUrl('animais')
    }
  }

  atualizarLimiteDeLinhas(): void {
    const linhasDisponiveisParaAdicionar = this.vagasDeAnimaisDisponiveis - this.totalDeLinhasAdicionadas
    this.adicionarMais = linhasDisponiveisParaAdicionar >= 10 ? 10 : linhasDisponiveisParaAdicionar
  }

  initTabela(): void {
    const qtdLinhasIniciais = this.vagasDeAnimaisDisponiveis >= 300 ? 300 : this.vagasDeAnimaisDisponiveis
    this.totalDeLinhasAdicionadas += qtdLinhasIniciais

    if (this.ativarComposicao) {
      this.colunas.meta_raca_composicao.type = 'dropdown'
      this.colunas.meta_raca_secundaria.type = 'dropdown'
      this.colunas.meta_raca_secundaria_composicao.type = 'dropdown'
      this.colunas.meta_raca_terciaria.type = 'dropdown'
      this.colunas.meta_raca_terciaria_composicao.type = 'dropdown'
    } else {
      this.colunas.meta_raca_composicao.type = 'hidden'
      this.colunas.meta_raca_secundaria.type = 'hidden'
      this.colunas.meta_raca_secundaria_composicao.type = 'hidden'
      this.colunas.meta_raca_terciaria.type = 'hidden'
      this.colunas.meta_raca_terciaria_composicao.type = 'hidden'
    }
    const data = this.tabela?.getData()
    this.tabela?.destroy()
    this.tabela = jexcel(this.spreadsheet.nativeElement, {
      data: [[]],
      includeHeadersOnDownload: true,
      tableOverflow: true,
      lazyLoading: true,
      lazyColumns: true,
      loadingSpin: true,
      tableHeight: '100%',
      tableWidth: '100%',
      // fullscreen: true,
      columns: Object.values(this.colunas),
      nestedHeaders: this.ativarComposicao ? [
        [
          {
            title: '',
            colspan: '1'
          },
          {
            title: 'Dados do animal',
            colspan: '3'
          },
          {
            title: 'Composição Racial',
            colspan: '6'
          },
          {
            title: 'Dados do animal',
            colspan: '4'
          },
          {
            title: 'Desmame',
            colspan: '2'
          },
          {
            title: 'Pesagens',
            colspan: '5'
          },
          {
            title: 'Extra',
            colspan: '2'
          },
          {
            title: 'Genealogia',
            colspan: '2'
          },
          {
            title: 'Registro e Pelagem',
            colspan: '5'
          }
        ]
      ] : [
        [
          {
            title: '',
            colspan: '1'
          },
          {
            title: 'Dados do animal',
            colspan: '8'
          },
          {
            title: 'Desmame',
            colspan: '2'
          },
          {
            title: 'Pesagens',
            colspan: '5'
          },
          {
            title: 'Extra',
            colspan: '2'
          },
          {
            title: 'Genealogia',
            colspan: '2'
          },
          {
            title: 'Registro e Pelagem',
            colspan: '5'
          }
        ]
      ],
      contextMenu: false,
      allowRenameColumn: false,
      allowDeleteColumn: false,
      allowDeleteRow: false,
      minDimensions: [10, qtdLinhasIniciais],
      autoIncrement: false,

      text: {
        noRecordsFound: 'Nenhum registro encontrado',
        showingPage: 'Mostrando página {0} de {1} entradas',
        show: 'Show',
        entries: 'entradas',
        insertANewColumnBefore: 'Inserir uma nova coluna antes de',
        insertANewColumnAfter: 'Inserir uma nova coluna depois de',
        deleteSelectedColumns: 'Excluir colunas selecionadas',
        renameThisColumn: 'Renomear esta coluna',
        orderAscending: 'ordem ascendente',
        orderDescending: 'Order decrescente',
        insertANewRowBefore: 'Inserir uma nova linha antes de',
        insertANewRowAfter: 'Inserir uma nova linha depois de',
        deleteSelectedRows: 'Excluir linhas selecionadas',
        editComments: 'Editar comentários',
        addComments: 'Adicionar comentários',
        comments: 'Comentários',
        clearComments: 'Limpar comentários',
        copy: 'Copiar ...',
        paste: 'Colar ...',
        saveAs: 'Salvar como ...',
        about: 'About',
        areYouSureToDeleteTheSelectedRows: 'Tem certeza de excluir as linhas selecionadas?',
        areYouSureToDeleteTheSelectedColumns: 'Tem certeza de excluir as colunas selecionadas?',
        thisActionWillDestroyAnyExistingMergedCellsAreYouSure:
          'Esta ação irá destruir todas as células mescladas existentes. Você tem certeza?',
        thisActionWillClearYourSearchResultsAreYouSure:
          'Esta ação limpará seus resultados de pesquisa. Você tem certeza?',
        thereIsAConflictWithAnotherMergedCell: 'Há um conflito com outra célula mesclada',
        invalidMergeProperties: 'Propriedades mescladas inválidas',
        cellAlreadyMerged: 'Célula já mesclada',
        noCellsSelected: 'Nenhuma célula selecionada'
      },

      onchange: (params, a1, coluna, linha, novoValor) => {
        const colunaSlug = this.colunasArray[coluna]
        if (colunaSlug == 'sexo') {
          if (novoValor.length && novoValor !== 'Macho' && ['M', 'm'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Macho')
            return
          }
          if (novoValor.length && novoValor !== 'Fêmea' && ['F', 'f'].includes(novoValor[0])) {
            this.tabela.setValueFromCoords(coluna, linha, 'Fêmea')
            return
          }
        }
        if (colunaSlug == 'idade_em_meses') {
          novoValor = Number(novoValor.replace(',', '.'))
          if (novoValor && !isNaN(novoValor)) {
            const dataNascimento = moment().subtract(novoValor, 'months').format('YYYY-MM-DD')
            const indexNascimento = this.colunasArray.indexOf('data_nascimento')
            this.tabela.setValueFromCoords(indexNascimento, linha, dataNascimento)
            return
          }
        }
        this.dadosASalvar = this.tabela.getData()
        if (coluna !== 0 && coluna !== this.colunasArray.indexOf('lote')) {
          clearTimeout(this.timeoutValidacao)
          this.timeoutValidacao = setTimeout(() => {
            this.validarLinha()
          }, 1000)
        }
      },

      oneditionstart: (params, param1, param2, param3, param4) => {
        console.log('oneditionstart', { params, param1, param2, param3, param4 })
        const coluna = this.tabela.getHeader(this.tabela.getSelectedColumns())
        console.log('COLUNA: ', coluna)

        if (coluna == 'Status') {
          this.utilsCtrl.showToast('Coluna somente para visualização do status da linha', 'bottom')
        }

        if (coluna == 'Pai') {
          this.selecionarReprodutorOuMatriz(param2, param3, 'reprodutor')
        }
        if (coluna == 'Mãe') {
          this.selecionarReprodutorOuMatriz(param2, param3, 'matriz')
        }
        if (coluna == 'Lote') {
          this.selecionarLote(param2, param3)
        }

        try {
          console.log('getSelectedRows', this.tabela.getSelectedRows())
        } catch (e) {
          console.error(e)
        }
        try {
          console.log('getSelectedColumns', this.tabela.getSelectedColumns())
        } catch (e) {
          console.error(e)
        }
        try {
          console.log('getHeader', this.tabela.getHeader(13))
        } catch (e) {
          console.error(e)
        }
        try {
        } catch (e) {
          console.error(e)
        }
      }
    })
    if (data) {
      this.tabela.setData(data)
    }
  }

  async validarLinha(): Promise<void> {
    document.querySelectorAll('td.pendente').forEach((td) => td.classList.remove('pendente'))
    document.querySelectorAll('td.alerta').forEach((td) => td.classList.remove('alerta'))
    document.querySelectorAll('tr.valido').forEach((tr) => tr.classList.remove('valido'))

    this.dadosASalvar = this.tabela.getData()
    const todosOsDados = JSON.parse(JSON.stringify(this.dadosASalvar))
    const numerosPreenchidos = []

    for (const linha of todosOsDados) {
      const numero = linha[this.colunasArray.indexOf('numeracao')]
      if (numero && numerosPreenchidos.indexOf(numero) == -1) numerosPreenchidos.push(numero)
    }

    const linhasAZerarLote = []

    let Nlinha = -1
    for (const dadosAtuais of todosOsDados) {
      Nlinha++

      const colunaStatus = dadosAtuais[0]
      dadosAtuais[0] = ''
      const valorPreenchido = dadosAtuais.join('')
      if (!valorPreenchido) {
        if (colunaStatus) {
          const colStatus = this.colunasArray.indexOf('status')
          this.tabela.setValueFromCoords(colStatus, Nlinha, '')
        }
        continue
      }

      const numeracao = dadosAtuais[this.colunasArray.indexOf('numeracao')]
      const nome = dadosAtuais[this.colunasArray.indexOf('nome')]
      const sexo = dadosAtuais[this.colunasArray.indexOf('sexo')]
      const raca = dadosAtuais[this.colunasArray.indexOf('raca')]
      const data_nascimento = dadosAtuais[this.colunasArray.indexOf('data_nascimento')]
      const lote = dadosAtuais[this.colunasArray.indexOf('lote')]

      if (lote && !lote.includes('lote:')) {
        linhasAZerarLote.push(Nlinha)
      }

      let novoValor = ''

      if (!nome && !numeracao) {
        const colNome = this.colunasArray.indexOf('nome')
        const colNumero = this.colunasArray.indexOf('numeracao')
        this.tabela.getCellFromCoords(colNome, Nlinha).classList.add('pendente')
        this.tabela.getCellFromCoords(colNumero, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Sem nome ou número</span>'
      }

      if (!sexo) {
        const colSexo = this.colunasArray.indexOf('sexo')
        this.tabela.getCellFromCoords(colSexo, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Selecione o sexo</span>'
      }

      if (!raca) {
        const colRaca = this.colunasArray.indexOf('raca')
        this.tabela.getCellFromCoords(colRaca, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Selecione a raça</span>'
      }

      if (!data_nascimento) {
        const colDataNascimento = this.colunasArray.indexOf('data_nascimento')
        this.tabela.getCellFromCoords(colDataNascimento, Nlinha).classList.add('pendente')
        novoValor = '<span style="color: #d32f2f">Digite a data de nascimento</span>'
      }

      if (numeracao && this.numeracaoDeAnimaisExistentes?.indexOf(numeracao) > -1) {
        const colNumero = this.colunasArray.indexOf('numeracao')
        this.tabela.getCellFromCoords(colNumero, Nlinha).classList.add('alerta')
      }

      if (!lote && this.loteProp) {
        const colLote = this.colunasArray.indexOf('lote')
        this.tabela.setValueFromCoords(
          colLote,
          Nlinha,
          `${this.loteProp.nome} <i style="display: none">lote:${this.loteProp.hash}</i>`
        )
      }

      if (!novoValor) {
        const colNumero = this.colunasArray.indexOf('numeracao')
        this.tabela.getCellFromCoords(colNumero, Nlinha)?.parentNode?.classList.add('valido')
        novoValor = '<span style="color: #006158">Ok</span>'
      }

      if (novoValor) {
        const colStatus = this.colunasArray.indexOf('status')
        this.tabela.setValueFromCoords(colStatus, Nlinha, novoValor)
      }
    }

    for (const linha of linhasAZerarLote) {
      this.tabela.setValueFromCoords(this.colunasArray.indexOf('lote'), linha, '')
    }
  }

  async selecionarReprodutorOuMatriz(coluna: number, linha: number, tipo: string): Promise<boolean> {
    const filtroSexo: NovoFiltro = {
      sexo: tipo === 'reprodutor' ? 'macho' : 'femea'
    }
    const modal = await this.modalCtrl.create({
      component: ModalListaAnimaisPage,
      componentProps: {
        filtrosFixos: ['sexo'],
        modalFiltro: filtroSexo,
        modalFiltroReprodutorExterno: filtroSexo
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()

    const { data } = await modal.onDidDismiss()

    if (data) {
      if (!data) return false
      console.log(data)

      if (!data.externo) {
        this.tabela.setValueFromCoords(
          coluna,
          linha,
          `${data.nome || data.numeracao} <i style="display: none;">genitor_interno:${data.hash}</i>`
        )
      } else {
        this.tabela.setValueFromCoords(
          coluna,
          linha,
          `${data.nome} <i style="display: none;">genitor_externo:${data.hash}</i>`
        )
      }
    }
  }

  async selecionarLote(coluna: number, linha: number): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalListaLotesPage,
      cssClass: 'custom-modal-lotes'
    })

    await modal.present()
    const { data } = await modal.onDidDismiss()

    if (data?.lotes) {
      const [lote] = data.lotes
      this.tabela.setValueFromCoords(coluna, linha, `${lote.nome} <i style="display: none">lote:${lote.hash}</i>`)
    }
  }

  adicionarMaisItens(): void {
    for (let a = 0; a < this.adicionarMais; a++) {
      this.tabela.insertRow(1)
    }

    this.totalDeLinhasAdicionadas += this.adicionarMais
    this.atualizarLimiteDeLinhas()
  }

  async salvarAnimais(): Promise<void> {
    this.dadosASalvar = this.tabela.getData()
    const linhasComConteudo = []
    const dadosASalvar = JSON.parse(JSON.stringify(this.dadosASalvar)) || []

    const indexesUsados = []
    let index = 0
    for (const linha of dadosASalvar) {
      const linhaTeste = JSON.parse(JSON.stringify(linha))

      linhaTeste[0] = ''

      const existeDados = linhaTeste.join('')
      if (existeDados) {
        linha.push(index)
        linhasComConteudo.push(linha)
      }
      index++
    }

    if (!linhasComConteudo.length) {
      this.utilsCtrl.showToast('Planilha vazia', 'bottom')
    }

    const loading = await this.utilsCtrl.showLoading('circular', 'Inserindo Animais...')

    const statusesValidos = ['<span style="color: #006158">Ok</span>']

    const payload = {
      animais: []
    }

    for (const linhaValida of linhasComConteudo) {
      if (statusesValidos.includes(linhaValida[0])) {
        indexesUsados.push(linhaValida[linhaValida.length - 1])

        // lote
        const loteSelecionado = {
          nome: linhaValida[this.colunasArray.indexOf('lote')].split('<i')[0].trim() || '',
          hash: linhaValida[this.colunasArray.indexOf('lote')].split('lote:')[1]?.replace('</i>', '') || ''
        }

        let raca = linhaValida[this.colunasArray.indexOf('raca')]
        if (raca) raca = Object.keys(Racas)[Object.values(Racas).indexOf(raca)]

        let meta_raca_secundaria = linhaValida[this.colunasArray.indexOf('meta_raca_secundaria')] || ''
        if(meta_raca_secundaria) {
          meta_raca_secundaria = Object.keys(Racas)[Object.values(Racas).indexOf(meta_raca_secundaria)]
        }
        let meta_raca_terciaria = linhaValida[this.colunasArray.indexOf('meta_raca_terciaria')] || ''
        if(meta_raca_terciaria) {
          meta_raca_terciaria = Object.keys(Racas)[Object.values(Racas).indexOf(meta_raca_terciaria)]
        }

        let data_nascimento = linhaValida[this.colunasArray.indexOf('data_nascimento')]

        if (data_nascimento.indexOf('/') > -1)
          linhaValida[this.colunasArray.indexOf('data_nascimento')] = moment(data_nascimento, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          )

        let data_desmame = linhaValida[this.colunasArray.indexOf('data_desmame')]

        if (data_desmame.indexOf('/') > -1)
          linhaValida[this.colunasArray.indexOf('data_desmame')] = moment(data_desmame, 'DD/MM/YYYY').format(
            'YYYY-MM-DD'
          )

        let peso_1_data = linhaValida[this.colunasArray.indexOf('peso_1_data')]

        if (peso_1_data.indexOf('/') > -1)
          linhaValida[this.colunasArray.indexOf('peso_1_data')] = moment(peso_1_data, 'DD/MM/YYYY').format('YYYY-MM-DD')

        let peso_2_data = linhaValida[this.colunasArray.indexOf('peso_2_data')]

        if (peso_2_data.indexOf('/') > -1)
          linhaValida[this.colunasArray.indexOf('peso_2_data')] = moment(peso_2_data, 'DD/MM/YYYY').format('YYYY-MM-DD')

        const animalSingle: IAnimalPayload = {
          hash: linhaValida[this.colunasArray.indexOf('hash')] || '',
          nome: linhaValida[this.colunasArray.indexOf('nome')],
          numeracao: linhaValida[this.colunasArray.indexOf('numeracao')],
          hash_lote: loteSelecionado.hash,
          raca,
          data_nascimento: linhaValida[this.colunasArray.indexOf('data_nascimento')].split(' ')[0],
          sexo: linhaValida[this.colunasArray.indexOf('sexo')] == 'Macho' ? 'macho' : 'femea',
          observacao: linhaValida[this.colunasArray.indexOf('observacao')] || '',
          situacao: 'vivo',
          situacao_produtiva: '',
          situacao_reprodutiva: '',
          situacao_sanitaria: '',
          tipo: 'bovino',
          meta_nbrinco: (linhaValida[this.colunasArray.indexOf('nbrinco')] || '').replace(/[^0-9]+/g, ''),
          meta_partos: linhaValida[this.colunasArray.indexOf('partos')] || '',
          meta_data_desmame: linhaValida[this.colunasArray.indexOf('data_desmame')].split(' ')[0],
          
          meta_raca_composicao: linhaValida[this.colunasArray.indexOf('meta_raca_composicao')] || '',
          meta_raca_secundaria,
          meta_raca_secundaria_composicao: linhaValida[this.colunasArray.indexOf('meta_raca_secundaria_composicao')] || '',
          meta_raca_terciaria,
          meta_raca_terciaria_composicao: linhaValida[this.colunasArray.indexOf('meta_raca_terciaria_composicao')] || '',

          meta_pelagem: linhaValida[this.colunasArray.indexOf('meta_pelagem')] || '',
          meta_registroDefinitivo: linhaValida[this.colunasArray.indexOf('meta_registroDefinitivo')] || '',
          meta_registroDeNascimento: linhaValida[this.colunasArray.indexOf('meta_registroDeNascimento')] || '',
          meta_dataRegistroDefinitivo: linhaValida[this.colunasArray.indexOf('meta_dataRegistroDefinitivo')] || '',
          meta_dataRegistroDeNascimento: linhaValida[this.colunasArray.indexOf('meta_dataRegistroDeNascimento')] || '',

          pesagens: {
            peso_1_valor: Number(linhaValida[this.colunasArray.indexOf('peso_1')].split(' ')[0]) || 0,
            peso_1_data: linhaValida[this.colunasArray.indexOf('peso_1_data')].split(' ')[0],
            peso_2_valor: Number(linhaValida[this.colunasArray.indexOf('peso_2')].split(' ')[0]) || 0,
            peso_2_data: linhaValida[this.colunasArray.indexOf('peso_2_data')].split(' ')[0],
            peso_nascimento: Number(linhaValida[this.colunasArray.indexOf('peso_ao_nascer')].split(' ')[0]) || 0,
            peso_desmame: Number(linhaValida[this.colunasArray.indexOf('peso_no_desmame')].split(' ')[0]) || 0,
            peso_atual: Number(linhaValida[this.colunasArray.indexOf('peso_atual')].split(' ')[0]) || 0
          },
          genealogia_reprodutor_externo: linhaValida[this.colunasArray.indexOf('pai')].includes('genitor_externo')
            ? linhaValida[this.colunasArray.indexOf('pai')].split('genitor_externo:')[1].replace('</i>', '')
            : null,
          genealogia_matriz_externa: linhaValida[this.colunasArray.indexOf('mae')].includes('genitor_externo')
            ? linhaValida[this.colunasArray.indexOf('mae')].split('genitor_externo:')[1].replace('</i>', '')
            : null,
          genealogia_reprodutor_interno: linhaValida[this.colunasArray.indexOf('pai')].includes('genitor_interno')
            ? linhaValida[this.colunasArray.indexOf('pai')].split('genitor_interno:')[1].replace('</i>', '')
            : null,
          genealogia_matriz_interna: linhaValida[this.colunasArray.indexOf('mae')].includes('genitor_interno')
            ? linhaValida[this.colunasArray.indexOf('mae')].split('genitor_interno:')[1].replace('</i>', '')
            : null
        }

        if (
          (animalSingle.numeracao || animalSingle.nome) &&
          animalSingle.data_nascimento &&
          animalSingle.sexo &&
          animalSingle.raca
        ) {
          payload.animais.push(animalSingle)
        }
      }
    }

    if (!payload.animais.length) {
      this.utilsCtrl.showToast('Nenhum animal válido', 'bottom')
    }

    if (payload.animais.length >= 500) {
      const totalRequests = Math.ceil(payload.animais.length / 500)
      for (let i = 0; i < totalRequests; i++) {
        const payloadMax500 = {
          animais: payload.animais.slice(i * 500, (i + 1) * 500)
        }

        const hashs = await this.getHashs(payloadMax500.animais.length)

        payloadMax500.animais.forEach((animal, index) => {
          if (!animal.hash) {
            animal.hash = hashs[index]
          }
        })

        if (payloadMax500.animais.length) {
          const [response, error] = await this.importacaoCtrl.salvarImportacaoAnimais(payloadMax500)
          if (response) {
            this.excluirLinhasAposCadastrarAnimal(response.hashs)

            this.utilsCtrl.showToast('Animais inseridos com sucesso!', 'bottom')
          }

          if (error) {
            const mensagemErro = error?.message || 'Erro ao inserir animais!'
            this.utilsCtrl.showToast(mensagemErro, 'bottom')
          }

          this.utilsCtrl.dismissLoading(loading)
        } else {
          this.utilsCtrl.dismissLoading(loading)
          this.utilsCtrl.showToast('Preencha os campos obrigatórios', 'bottom')
        }
      }
    } else {
      const hashs = await this.getHashs(payload.animais.length)

      this.inserirHashColuna(hashs)

      payload.animais.forEach((animal, index) => {
        if (!animal.hash) {
          animal['hash'] = hashs[index]
        }
      })

      if (payload.animais.length) {
        const [response, error] = await this.importacaoCtrl.salvarImportacaoAnimais(payload)

        if (response) {
          this.excluirLinhasAposCadastrarAnimal(response.hashs)
          this.utilsCtrl.showToast('Animais inseridos com sucesso!', 'bottom')

          if (this.isModal) {
            this.modalCtrl.dismiss({ hashs: response.hashs })
          }
        }

        if (error) {
          const mensagemErro = error?.message || 'Erro ao inserir animais!'
          this.utilsCtrl.showToast(mensagemErro, 'bottom')
        }

        this.utilsCtrl.dismissLoading(loading)
      } else {
        this.utilsCtrl.dismissLoading(loading)
        this.utilsCtrl.showToast('Preencha os campos obrigatórios', 'bottom')
      }
    }

    if(payload.animais.length) {
      VastaRX.setState('atualizar-cartao-usuario', true)
    }
  }

  async getHashs(qtd: number): Promise<string[]> {
    const [response, error] = await this.propriedadesCtrl.gerarHashs(qtd)

    if (response) {
      return response
    }

    if (error) {
      throw error
    }
  }

  excluirLinhasAposCadastrarAnimal(hashs: string[]): void {
    this.dadosASalvar = this.tabela.getData()
    const arrayHashs = [...hashs]
    const todosOsDados = JSON.parse(JSON.stringify(this.dadosASalvar))
    let Nlinha = todosOsDados.length

    let linhasRemovidas = 0

    const reversedDados = todosOsDados.reverse()

    this.tabela.el.jexcel.options = { ...this.tabela.el.jexcel.options, allowDeleteRow: true }
    for (const dadosAtuais of reversedDados) {
      Nlinha--

      const hash = dadosAtuais[this.colunasArray.indexOf('hash')]
      const status = dadosAtuais[this.colunasArray.indexOf('status')]

      if (status?.includes('Ok') && arrayHashs.includes(hash)) {
        this.tabela.deleteRow(Nlinha, 1)

        linhasRemovidas++

        const totalDeLinhasNoMomento = todosOsDados.length
        const isTodasLinhasRemovidas = totalDeLinhasNoMomento === linhasRemovidas
    
        if(isTodasLinhasRemovidas) {
          this.router.navigateByUrl('/animais')
        }
      }
    }
    this.tabela.el.jexcel.options = { ...this.tabela.el.jexcel.options, allowDeleteRow: false }
  }

  adicionardadosLinhas(): void {
    const dados = this.tabela.getData()
    let linhaInicial = 0
    // verificar onde começa as linhas vazias
    dados.forEach((item, index) => {
      if (!this.verificarValoresVazios(item)) {
        linhaInicial = index + 1
      }
    })

    this.alertInserirRangeNumeracao(linhaInicial)
  }

  verificarValoresVazios(array: string[]): boolean {
    const lenght = array.length
    let colunasVazias = 0
    array.forEach((item) => {
      if (item.length === 0) {
        colunasVazias++
      }
    })

    if (colunasVazias === lenght) return true
    else return false
  }

  async alertInserirRangeNumeracao(linhaInicial: number): Promise<void> {
    const alert = await this.alertCtrl.create({
      header: 'Digite o intervalo de brincos',
      message: 'Ex: De 112 até 158',
      inputs: [
        {
          type: 'text',
          placeholder: 'Prefixo (letras)(opcional)',
          name: 'prefixo'
        },
        {
          type: 'number',
          placeholder: 'De',
          name: 'de'
        },
        {
          type: 'number',
          placeholder: 'Até',
          name: 'ate'
        }
      ],
      buttons: [
        {
          text: 'Cancelar'
        },
        {
          text: 'Confirmar',
          handler: (values): void => {
            if (Number(values.de) > Number(values.ate)) {
              this.utilsCtrl.showToast('Valor inicial deve ser menor que valor final', 'bottom')
            } else {
              const range = values.ate - values.de + 1
              const arrayNumeracao = []
              for (let i = 0; i < range; i++) {
                arrayNumeracao.push(`${values.prefixo}${values.prefixo ? '-' : ''}${Number(values.de) + i}`)
              }
              const colunasKeys = Object.keys(this.colunas)
              const dados = []

              arrayNumeracao.forEach((item) => {
                const linha = []
                colunasKeys.forEach((coluna) => {
                  linha.push('')
                })
                linha[4] = item
                dados.push(linha)
              })

              this.inserirDadosLinha(linhaInicial, dados)
            }
          }
        }
      ]
    })

    await alert.present()
  }

  async inserirDadosLinha(linhaInicial: number, arrayDados: unknown[]): Promise<void> {
    const [hashs] = await this.propriedadesCtrl.gerarHashs(arrayDados.length)

    arrayDados.forEach((item, index) => {
      item[1] = hashs[index]
    })

    Array.from(arrayDados).forEach((item, index) => {
      this.tabela.setRowData(linhaInicial + index, item)
    })
  }

  inserirHashColuna(hashs: string[]): void {
    this.dadosASalvar = this.tabela.getData()

    const todosOsDados = JSON.parse(JSON.stringify(this.dadosASalvar))

    const arrayHashs = [...hashs]
    let Nlinha = -1

    for (const dadosAtuais of todosOsDados) {
      Nlinha++

      const hash = dadosAtuais[this.colunasArray.indexOf('hash')]
      const status = dadosAtuais[this.colunasArray.indexOf('status')]

      if (!hash && status.includes('Ok')) {
        const hashGerado = arrayHashs[arrayHashs.length - 1]
        arrayHashs.pop()
        const colHash = this.colunasArray.indexOf('hash')
        this.tabela.setValueFromCoords(colHash, Nlinha, hashGerado)
      }
    }
  }

  async closeModal(): Promise<void> {
    await this.modalCtrl.dismiss()
  }
}
