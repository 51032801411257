import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PopoverController } from '@ionic/angular'
import * as moment from 'moment'
import { PropriedadesService } from 'src/app/services/propriedades.service'
import { PopoverListaExposicoesComponent } from '../popover-lista-exposicoes/popover-lista-exposicoes.component'
import { PopoverOpcoesManejoComponent } from '../popover-opcoes-manejo/popover-opcoes-manejo.component'
import { PopoverSeletorComponent } from '../popover-seletor/popover-seletor.component'

@Component({
  selector: 'app-manejo-reprodutivo-diagnostico-gestacao',
  templateUrl: './manejo-reprodutivo-diagnostico-gestacao.component.html',
  styleUrls: ['./manejo-reprodutivo-diagnostico-gestacao.component.scss']
})
export class ManejoReprodutivoDiagnosticoGestacaoComponent implements OnInit {
  @Input('dados') dados = {
    dg_resultado: '',
    dg_hash_exposicao: '',
    dg_periodo_gestacional: '',
    observacao: '',
    hash_animal: '',
    dg_ovario_esquerdo: '',
    dg_ovario_direito: '',
    dg_tonus_uterino: '',
    edited: true
  }
  @Input('data') data: string
  @Input('exposicoes') exposicoes = {}
  @Input('exposicoesAssociadas') exposicoesAssociadas = {}
  @Output() dadosChange = new EventEmitter<unknown>()
  @Output() observacaoChange = new EventEmitter<unknown>()
  public dataConcepcao = ''
  public dataPrevisaoParto = ''

  public manejosReprodutivosAvancados = false

  public opcoesOvario = {
    ativo: { texto: 'Ativo'},
    inativo: { texto: 'Inativo'},
    corpo_luteo: { texto: 'Corpo Lúteo'},
  }

  constructor(
    private popoverCtrl: PopoverController,
    public propriedadesCtrl: PropriedadesService
  ) { }

  ngOnInit(): void {
    this.calcularPrevisao()
    this.verificarManejosReprodutivosAvancados()
  }

  emit(): void {
    this.calcularPrevisao()
    this.dadosChange.emit(this.dados)
  }

  verificarManejosReprodutivosAvancados(): void {
    if(Number(this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.manejos_reprodutivos_avancados)){
      this.manejosReprodutivosAvancados = true
    } else {
      this.manejosReprodutivosAvancados = false
    }
  }

  calcularPrevisao(): void {
    if (this.dados.dg_periodo_gestacional) {
      this.dataConcepcao = moment(this.data).subtract(this.dados.dg_periodo_gestacional, 'days').format('DD/MM/YYYY')

      const tempoGestacao = Number(this.propriedadesCtrl?.propriedadeSelecionada?.metadados?.tempo_gestacao || 283)

      this.dataPrevisaoParto = moment(this.dataConcepcao, 'DD/MM/YYYY').add(tempoGestacao, 'days').format('DD/MM/YYYY')
    }
  }

  adicionarObservacao(): void {
    this.observacaoChange.emit(this.dados)
  }

  async showPopoverListaExposicoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverListaExposicoesComponent,
      cssClass: 'popover-lista-exposicoes',
      componentProps: { exposicoes: this.exposicoes[this.dados.hash_animal] || [] },
      event,
      reference: 'event'
    })
    await popover.present()
    const { data } = await popover.onWillDismiss()
    if (data) {
      this.dados.dg_periodo_gestacional = moment(this.data).diff(data.data, 'days').toString()
      this.dados.dg_hash_exposicao = data.hash
      this.dados.edited = true
      this.emit()
    }
  }

  async showPopoverOpcoes(event: Event): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverOpcoesManejoComponent,
      event,
      reference: 'event'
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()
    if (data?.limpar) {
      this.dados.edited = true
      this.dados.observacao = ''
      this.dados.dg_resultado = null
      this.dados.dg_hash_exposicao = null
      this.dados.dg_periodo_gestacional = null
      this.dados.dg_ovario_esquerdo = null
      this.dados.dg_ovario_direito = null
      this.dados.dg_tonus_uterino = null
      this.emit()
    }
  }

  async showPopoverOpcoesOvario(event: Event, ovario: string): Promise<void> {
    const popover = await this.popoverCtrl.create({
      component: PopoverSeletorComponent,
      event,
      reference: 'trigger',
      componentProps: {
        opcoesKeys: Object.keys(this.opcoesOvario),
        opcoes: this.opcoesOvario,
      },
    })

    await popover.present()
    const { data } = await popover.onDidDismiss()

    if (data) {
      if (ovario == 'esquerdo'){
        this.dados.dg_ovario_esquerdo = data
      }

      if (ovario == 'direito'){
        this.dados.dg_ovario_direito = data
      }
      this.dados.edited = true
      this.emit()
    }
  }
}
