import { Injectable } from '@angular/core'
import { IAnimal, IReprodutorExterno } from '../utils/interfaces/animais.interface'
import { IArea, IAreaPiquete } from '../utils/interfaces/areas.interface'
import { EstoqueFarmacia, EstoqueNutricional } from '../utils/interfaces/estoque.interface'
import { ILote } from '../utils/interfaces/lotes.interface'
import {
  IGenealogiaPaisAgrupado,
  IGenealogiaPartoAgrupadosPorHash,
  IListaTodosManejos,
  IManejoAreaPastejoRotacionado,
  IRetornoGenealogiaParto,
  ListaManejoReprodutivo,
  ManejoArea,
  ManejoSanitario,
  ResponseListaTodosManejos,
  ResponseExposicoesData,
  TodosManejos
} from '../utils/interfaces/manejos.interface'
import { NovoQueryParamsModel, QueryParamsmModel } from '../utils/interfaces/query-params.interface'
import { ApiService } from './api/api.service'
import { PermissoesUsuarioService } from './permissoes-usuario.service'
import { Pessoa } from '../utils/interfaces/financas.interface'
import { StatusRequest } from '../utils/interfaces/statusRequest.interface'
import { ApiError } from './importacao.service'

export type IPopoverCampos = {
  ocultarPreencherTodos?: boolean
  campos?: IPopoverCamposCampos[]
  camposManejoNutricional?: { [key: string]: { titulo: string; campos?: IPopoverCamposCampos[] } }
  camposManejoSanitarioNovo?: { [key: string]: { titulo: string; campos?: IPopoverCamposCampos[] } }
  opcoes?: { label: string; value: string }[]
  valorChave?: string
}
export type IPopoverCamposGroup = {
  [key: string]: IPopoverCampos
}
export type IPopoverCamposCampos = {
  label: string
  value: string
  chave: string
  chaveExtra?: string
  tipoCampo: 'manejo-button' | 'manejo-number' | 'manejo-segment' | 'manejo-input' | 'manejo-select'
  extra?: boolean
  unidade?: string
}
export const PopoverCampos: IPopoverCamposGroup = {
  pesagem: { campos: [{ label: 'Peso', value: '', chave: 'peso', tipoCampo: 'manejo-number' }] },
  'altura-capim': {
    ocultarPreencherTodos: true,
    campos: [
      { label: 'Altura', value: '', chave: 'altura_capim', tipoCampo: 'manejo-number' }
    ]
  },
  'pastejo-rotacionado': {
    ocultarPreencherTodos: true,
    campos: [
      { label: 'Piquetes', value: '', chave: 'hash_piquete', tipoCampo: 'manejo-button' }
    ]
  },
  ordenha: {
    campos: [
      { label: 'Ordenha 1', value: '', chave: 'primeira_ordenha', tipoCampo: 'manejo-number' },
      { label: 'Ordenha 2', value: '', chave: 'segunda_ordenha', tipoCampo: 'manejo-number' },
      { label: 'Ordenha 3', value: '', chave: 'terceira_ordenha', tipoCampo: 'manejo-number' }
    ]
  },
  'movimentacao-lote': { campos: [{ label: 'Lote', value: '', chave: 'hash_lote', tipoCampo: 'manejo-button' }] },
  'movimentacao-confinamento': {
    campos: [{ label: 'Confinamento', value: '', chave: 'hash_confinamento', tipoCampo: 'manejo-button' }]
  },
  'numero-controle': {
    campos: [{ label: 'Alteração de Número', value: '', chave: 'numero_novo', tipoCampo: 'manejo-input' }]
  },
  'numero-brinco-eletronico': {
    campos: [
      { label: 'Alteração de Brinco Eletrônico', value: '', chave: 'brinco_eletronico', tipoCampo: 'manejo-input' }
    ]
  },
  perda: {
    opcoes: [
      { label: 'Perdido', value: 'perdido' },
      { label: 'Não Perdido', value: 'nao-perdido' }
    ],
    campos: [{ label: 'Perda', value: '', chave: 'perda_observacoes', tipoCampo: 'manejo-segment' }]
  },
  secagem: {
    opcoes: [
      { label: 'Iniciar Secagem', value: 'secar' },
      { label: 'Não Iniciar', value: 'nao-secar' }
    ],
    campos: [{ label: 'Secagem', value: '', chave: 'secagem_observacoes', tipoCampo: 'manejo-segment' }]
  },
  morte: {
    opcoes: [
      { label: 'Morto', value: 'morto' },
      { label: 'Vivo', value: 'vivo' }
    ],
    campos: [{ label: 'Morte', value: '', chave: 'morte_observacoes', tipoCampo: 'manejo-segment' }]
  },
  observacao: { campos: [{ label: 'Observação', value: '', chave: 'observacao', tipoCampo: 'manejo-input' }] },
  cio: {
    opcoes: [
      { label: 'Positivo', value: 'positivo' },
      { label: 'Negativo', value: 'negativo' }
    ],
    campos: [{ label: 'Resultado', value: '', chave: 'cio_resultado', tipoCampo: 'manejo-segment' }]
  },
  descarte: {
    opcoes: [
      { label: 'Realizado', value: 'realizado' },
      { label: 'Não Realizado', value: 'nao-realizado' }
    ],
    campos: [{ label: 'Resultado', value: '', chave: 'descarte_resultado', tipoCampo: 'manejo-segment' }]
  },
  implante: {
    opcoes: [
      { label: 'Implantado', value: 'realizado' },
      { label: 'Não Implantado', value: 'nao-realizado' }
    ],
    campos: [{ label: 'Resultado', value: '', chave: 'implante_aplica', tipoCampo: 'manejo-segment' }]
  },
  'implante-remocao': {
    opcoes: [
      { label: 'Removido', value: 'realizado' },
      { label: 'Não Removido', value: 'nao-realizado' }
    ],
    campos: [{ label: 'Resultado', value: '', chave: 'implante_remove', tipoCampo: 'manejo-segment' }]
  },
  dg: {
    opcoes: [
      { label: 'Positivo', value: 'positivo' },
      { label: 'Negativo', value: 'negativo' }
    ],
    campos: [{ label: 'Resultado', value: '', chave: 'dg_resultado', tipoCampo: 'manejo-segment' }]
  },
  inseminacao: {
    campos: [
      {
        label: 'Embrião/Sêmen',
        value: '',
        chave: 'inseminacao_semen',
        chaveExtra: 'inseminacao_embriao',
        tipoCampo: 'manejo-button'
      },
      {
        label: 'Inseminador',
        value: '',
        chave: 'inseminacao_inseminador',
        tipoCampo: 'manejo-button',
      }
    ]
  },
  'monta-natural': {
    campos: [
      {
        label: 'Touro',
        value: '',
        chave: 'cobertura_hash_reprodutor',
        chaveExtra: 'cobertura_hash_reprodutor_externo',
        tipoCampo: 'manejo-button'
      }
    ]
  },
  desmame: {
    opcoes: [
      { label: 'Realizado', value: 'realizado' },
      { label: 'Não Realizado', value: 'nao-realizado' }
    ],
    campos: [{ label: 'Desmame', value: '', chave: 'desmame_detalhes', tipoCampo: 'manejo-segment' }]
  },
  parto: {
    campos: [
      { label: 'Cria', value: '', chave: 'parto_hash_cria1', tipoCampo: 'manejo-button' },
      { label: 'Cria 2', value: '', chave: 'parto_hash_cria2', tipoCampo: 'manejo-button' },
      { label: 'Cria 3', value: '', chave: 'parto_hash_cria3', tipoCampo: 'manejo-button' }
    ]
  },
  aborto: {
    opcoes: [
      { label: 'Realizado', value: 'realizado' },
      { label: 'Não Realizado', value: 'nao-realizado' }
    ],
    campos: [{ label: 'Aborto', value: '', chave: 'aborto_detalhes', tipoCampo: 'manejo-segment' }]
  },
  castracao: {
    opcoes: [
      { label: 'Realizada', value: 'realizado' },
      { label: 'Não Realizada', value: 'nao-realizado' }
    ],
    campos: [{ label: 'Castração', value: '', chave: 'castracao_detalhes', tipoCampo: 'manejo-segment' }]
  },
  escore: {
    campos: [{ label: 'Escore', value: '', chave: 'escore_corporal', tipoCampo: 'manejo-input' }]
  },
  'transferencia-propriedade': {
    opcoes: [
      { label: 'Movido', value: 'movido' },
      { label: 'Não Movido', value: 'nao-movido' }
    ],
    campos: [
      { label: 'Movido', value: '', chave: 'novo_status', tipoCampo: 'manejo-segment' },
      { label: 'Propriedade', value: '', chave: 'transf_propriedade_id', tipoCampo: 'manejo-select' },
      { label: 'Lote', value: '', chave: 'transf_propriedade_hash_lote', tipoCampo: 'manejo-select' }
    ]
  },
  nutricao: {
    campos: []
  },
  sanitarioEstoque: {
    campos: []
  },
  'movimentacao-entre-areas': {
    campos: [{ label: 'Área', value: '', chave: 'hash_area_destino', tipoCampo: 'manejo-button' }]
  },
  'escore-fezes': {
    campos: [{ label: 'Escore de Fezes', value: '', chave: 'escore_fezes', tipoCampo: 'manejo-input' }]
  },
  'perimetro-escrotal': {
    campos: [{ label: 'Perímetro', value: '', chave: 'perimetro_escrotal', tipoCampo: 'manejo-input' }]
  },
  'aprumos': {
    campos: [{ label: 'Escore', value: '', chave: 'aprumos', tipoCampo: 'manejo-input' }]
  },
  'boca': {
    campos: [{ label: 'Escore', value: '', chave: 'boca', tipoCampo: 'manejo-input' }]
  },
  'casco': {
    campos: [{ label: 'Escore', value: '', chave: 'casco', tipoCampo: 'manejo-input' }]
  },
  'chanfro': {
    campos: [{ label: 'Escore', value: '', chave: 'chanfro', tipoCampo: 'manejo-input' }]
  },
  'frame': {
    campos: [{ label: 'Escore', value: '', chave: 'frame', tipoCampo: 'manejo-input' }]
  },
  'garupa': {
    campos: [{ label: 'Escore', value: '', chave: 'garupa', tipoCampo: 'manejo-input' }]
  },
  'insercao-cauda': {
    campos: [{ label: 'Escore', value: '', chave: 'insercao_cauda', tipoCampo: 'manejo-input' }]
  },
  'linha-dorso': {
    campos: [{ label: 'Escore', value: '', chave: 'linha_dorso', tipoCampo: 'manejo-input' }]
  },
  'musculosidade': {
    campos: [{ label: 'Escore', value: '', chave: 'musculosidade', tipoCampo: 'manejo-input' }]
  },
  'ossatura': {
    campos: [{ label: 'Escore', value: '', chave: 'ossatura', tipoCampo: 'manejo-input' }]
  },
  'pelagem': {
    campos: [{ label: 'Escore', value: '', chave: 'pelagem', tipoCampo: 'manejo-input' }]
  },
  'profundidade': {
    campos: [{ label: 'Escore', value: '', chave: 'profundidade', tipoCampo: 'manejo-input' }]
  },
  'racial': {
    campos: [{ label: 'Escore', value: '', chave: 'racial', tipoCampo: 'manejo-input' }]
  },
  'reproducao': {
    campos: [{ label: 'Escore', value: '', chave: 'reproducao', tipoCampo: 'manejo-input' }]
  },
  'temperamento': {
    campos: [{ label: 'Pontuação', value: '', chave: 'temperamento', tipoCampo: 'manejo-input' }]
  },
  'torcao-testicular': {
    campos: [{ label: 'Escore', value: '', chave: 'torcao_testicular', tipoCampo: 'manejo-input' }]
  },
  'ubere': {
    campos: [{ label: 'Escore', value: '', chave: 'ubere', tipoCampo: 'manejo-input' }]
  },
  'umbigo': {
    campos: [{ label: 'Escore', value: '', chave: 'umbigo', tipoCampo: 'manejo-input' }]
  },
}
export const PopoverCampoManejoSanitario: {
  campos?: IPopoverCamposCampos[]
  opcoes?: { label: string; value: string }[]
  valorChave?: string
} = {
  opcoes: [
    { label: 'Aplicado', value: 'aplicado' },
    { label: 'Não aplicado', value: 'nao-aplicado' }
  ],
  campos: [
    { label: '', chave: 'aplicado', value: '', tipoCampo: 'manejo-select' },
    { label: 'Descrição', chave: 'descricao', value: '', tipoCampo: 'manejo-input' }
  ]
}

export type ManejoPesagem = {
  balanca?: number
  created_at?: string
  data_pesagem: string
  especie?: string
  gmd?: number
  hash?: string
  hash_animal: string
  hash_lote?: string
  hash_pesagem_pai?: string
  id?: number
  id_propriedade?: number
  identificador?: string
  observacao?: string
  peso: number
  status?: number
  synced_at?: string
  updated_at?: string

  edited?: boolean
}

export type ManejoOrdenha = {
  hash?: string
  id_propriedade?: number
  hash_animal?: string
  especie?: string
  data?: string
  quantidade?: number
  primeira_ordenha?: number
  segunda_ordenha?: number
  terceira_ordenha?: number
  observacao?: string
  status?: number
  created_at?: string
  updated_at?: string
  synced_at?: string

  edited?: boolean
}

export interface IManejoGeralUnitario {
  id?: number
  status?: number
  id_propriedade?: number
  hash?: string

  procedimento?: IManejoGeralTipos
  observacao?: string
  hash_animal?: string
  data?: string

  hash_lote?: string
  hash_confinamento?: string
  sem_lote?: string
  numero_novo?: string
  brinco_eletronico?: string
  perda_observacoes?: string
  secagem_observacoes?: string
  morte_causa?: string
  morte_observacoes?: string
  morte_imagem_1?: string
  morte_imagem_2?: string
  morte_imagem_3?: string
  morte_imagem_4?: string
  morte_imagem_5?: string
  morte_latitude?: string
  morte_longitude?: string
  transf_propriedade_id?: number
  transf_propriedade_novo_hash?: string
  transf_propriedade_hash_lote?: string
  venda_hash_transacao?: string
  perimetro_escrotal?: string

  chuva_quantidade?: number

  responsavel_tecnico?: string
  novo_status?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao' | 'migracao'

  manejo_original?: string

  created_at?: string
  updated_at?: string
  synced_at?: string

  edited?: boolean
}
export interface IManejoGeneticaUnitario {
  id?: number
  status?: number
  id_propriedade?: number
  hash?: string

  procedimento?: IManejoGeneticaTipos
  observacao?: string
  hash_animal?: string
  data?: string

  perimetro_escrotal?: string
  musculosidade?: string
  temperamento?: string
  aprumos?: string
  profundidade?: string
  reproducao?: string
  pelagem?: string
  ossatura?: string
  insercao_cauda?: string
  garupa?: string
  frame?: string
  boca?: string
  umbigo?: string
  ubere?: string
  torcao_testicular?: string
  racial?: string
  casco?: string
  linha_dorso?: string
  chanfro?: string

  responsavel_tecnico?: string
  novo_status?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao' | 'migracao'

  manejo_original?: string

  created_at?: string
  updated_at?: string
  synced_at?: string

  edited?: boolean
}

export interface IManejoLote {
  id?: number
  status?: number
  id_propriedade?: number
  hash?: string

  procedimento?: 'movimentacao-entre-areas' | 'observacao' | 'escore-fezes'
  observacao?: string
  hash_lote?: string
  data?: string

  hash_area_destino?: string
  sem_area?: string

  escore_fezes?: string

  responsavel_tecnico?: string
  novo_status?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao' | 'migracao'

  manejo_original?: string

  created_at?: string
  updated_at?: string
  synced_at?: string

  edited?: boolean
}

export type IManejoLoteTipos = 'movimentacao-entre-areas' | 'nutricao' | 'observacao' | 'escore-fezes'

export const manejoLoteTipos: IManejoLoteTipos[] = ['movimentacao-entre-areas', 'nutricao', 'observacao']

export type IManejoAreaTipos = 'chuva' | 'nutricao' | 'altura-capim' | 'pastejo-rotacionado' | 'observacao'

export const manejoAreaTipos: IManejoAreaTipos[] = ['chuva', 'nutricao', 'altura-capim', 'pastejo-rotacionado']

export type IManejoGeneticaTipos =
  | 'aprumos'
  | 'boca'
  | 'casco'
  | 'chanfro'
  | 'frame'
  | 'garupa'
  | 'insercao-cauda'
  | 'linha-dorso'
  | 'musculosidade'
  | 'ossatura'
  | 'pelagem'
  | 'perimetro-escrotal'
  | 'profundidade'
  | 'racial'
  | 'reproducao'
  | 'temperamento'
  | 'torcao-testicular'
  | 'ubere'
  | 'umbigo'

export type IManejoGeralTipos =
  | 'morte'
  | 'perda'
  | 'numero-controle'
  | 'numero-brinco-eletronico'
  | 'movimentacao-lote'
  | 'movimentacao-confinamento'
  | 'perimetro-escrotal'
  | 'transferencia-propriedade'
  | 'venda'
  | 'secagem'
  | 'observacao'

export const manejoGeralTipos: IManejoGeralTipos[] = [
  'morte',
  'perda',
  'numero-controle',
  'numero-brinco-eletronico',
  'movimentacao-lote',
  'movimentacao-confinamento',
  'transferencia-propriedade',
  'venda',
  'secagem',
  'observacao'
]

export const manejoReprodutivoTipos = [
  'castracao',
  'aborto',
  'monta-natural',
  'inseminacao',
  'dg',
  'parto',
  'desmame',
  'cio',
  'implante',
  'implante-remocao',
  'escore'
]

export type IManejosSolicitacoesRetornoTipos =
  | 'ordenha'
  | 'pesagem'
  | 'diagnostico-gestacao'
  | 'diagnostico-cio'
  | 'implante-progesterona'
  | 'remocao-implante'
  | 'inseminacao-artificial'
  | 'monta-natural'
  | 'parto'
  | 'aborto'
  | 'escore-corporal'
  | 'desmame'
  | 'associacao-de-brinco'
  | 'alteracao-numero'
  | 'movimentacao-entre-lotes'
  | 'enviar-para-confinamento'
  | 'registrar-morte'
  | 'sanitario'
  | 'descarte'
  | 'castracao'
  | 'nutricional'
  | 'sanitarioEstoque'
  | 'secagem'
  | 'perda'
  | 'observacao'
  | 'movimentacao-entre-areas'
  | 'escore-fezes'
  | 'aprumos'
  | 'boca'
  | 'casco'
  | 'chanfro'
  | 'frame'
  | 'garupa'
  | 'insercao-cauda'
  | 'linha-dorso'
  | 'musculosidade'
  | 'ossatura'
  | 'pelagem'
  | 'perimetro-escrotal'
  | 'profundidade'
  | 'racial'
  | 'reproducao'
  | 'temperamento'
  | 'torcao-testicular'
  | 'ubere'
  | 'umbigo'
  | 'altura-capim'
  | 'pluviometria'
  | 'pastejo-rotacionado'

export type IManejoReprodutivoTipos =
  | 'castracao'
  | 'aborto'
  | 'monta-natural'
  | 'inseminacao'
  | 'dg'
  | 'parto'
  | 'desmame'
  | 'cio'
  | 'implante'
  | 'implante-remocao'
  | 'escore'
  | 'descarte'

export type ManejoNutricional = {
  id?: number
  hash?: string
  id_propriedade?: number
  observacao?: string
  data?: string
  responsavel_tecnico?: string
  hash_animal?: string
  hash_lote?: string
  hash_area?: string

  nutricional_hash_estoque?: string
  nutricional_quantidade?: number
  nutricional_duracao?: number

  novo_status?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao'
  manejo_original?: string
  status?: number
  created_at?: string
  updated_at?: string
  synced_at?: string
}

export type ManejoSanitarioEstoque = {
  id?: number
  hash?: string
  id_propriedade?: number
  observacao?: string
  data?: string
  responsavel_tecnico?: string
  hash_animal?: string
  hash_lote?: string
  hash_area?: string

  edited?: boolean

  sanitario_hash_estoque?: string
  sanitario_quantidade?: number

  novo_status?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao'
  manejo_original?: string
  status?: number
  created_at?: string
  updated_at?: string
  synced_at?: string
}

export type ManejoReprodutivoUnitario = {
  id?: number
  status?: number
  id_propriedade?: number
  hash?: string

  hash_animal?: string
  data?: string
  procedimento?: IManejoReprodutivoTipos

  observacao?: string
  responsavel_tecnico?: string
  escore_corporal?: string
  novo_status?: string
  dg_resultado?: string
  dg_periodo_gestacional?: string
  dg_previsao_de_parto?: string
  dg_hash_exposicao?: string
  dg_ovario_direito?: string
  dg_ovario_esquerdo?: string
  dg_tonus_uterino?: string
  inseminacao_semen?: string
  inseminacao_inseminador?: string
  inseminacao_ovulo?: string
  inseminacao_embriao?: string
  inseminacao_bastao?: string
  inseminacao_muco?: string
  cio_resultado?: string
  cobertura_hash_reprodutor?: string
  cobertura_hash_reprodutor_externo?: string
  implante_aplica?: string
  implante_remove?: string
  desmame_detalhes?: 'realizado' | 'nao-realizado'
  aborto_detalhes?: string
  castracao_detalhes?: string
  parto_hash_cria1?: string
  parto_hash_cria2?: string
  parto_hash_cria3?: string
  parto_intervalo?: string
  descarte_resultado?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao' | 'migracao'
  identificador?: string
  manejo_original?: string

  created_at?: string
  updated_at?: string
  synced_at?: string

  edited?: boolean
}

export const manejoSanitarioTipos = [
  'medicacao',
  'vacinacao',
  'vermifugacao',
  'procedimentos-veterinarios',
  'exames',
  'banho',
  'complexo-vitaminico',
  'tratamento-clinico',
  'antiparasitarios',
  'outros',
  'diagnostico',
  'aftosa',
  'brucelose'
]

export type IManejoSanitarioTipos =
  | 'medicacao'
  | 'vacinacao'
  | 'vermifugacao'
  | 'procedimentos-veterinarios'
  | 'exames'
  | 'banho'
  | 'complexo-vitaminico'
  | 'tratamento-clinico'
  | 'antiparasitarios'
  | 'outros'
  | 'diagnostico'
  | 'aftosa'
  | 'brucelose'

export type ManejoSanitarioUnitario = {
  aplicado?: boolean

  id?: number
  status?: number
  id_propriedade?: number
  hash?: string

  hash_procedimento?: string
  procedimento?: IManejoSanitarioTipos
  descricao?: string
  observacao?: string
  hash_animal?: string
  hash_lote?: string
  hash_area?: string
  data?: string

  responsavel_tecnico?: string
  novo_status?: string
  origem?: 'importacao' | 'cadastro' | 'aprovacao' | 'migracao'

  manejo_original?: string

  created_at?: string
  updated_at?: string
  synced_at?: string

  edited?: boolean
}

export const labelsSituacoesSanitarias = {
  medicacao: 'Medicação',
  vacinacao: 'Vacinação',
  vermifugacao: 'Vermifugação',
  'procedimentos-veterinarios': 'Procedimentos Veterinários',
  exames: 'Exames',
  banho: 'Banho',
  'complexo-vitaminico': 'Complexo Vitaminico',
  'tratamento-clinico': 'Tratamento Clínico',
  antiparasitarios: 'Antiparasitários',
  outros: 'Outros',
  diagnostico: 'Diagnóstico',
  aftosa: 'Aftosa',
  brucelose: 'Brucelose'
}

export const labelsManejosGerais = {
  observacao: 'Observação',
  'numero-controle': 'Alteração de Número',
  'numero-brinco-eletronico': 'Alteração de Brinco Eletrônico',
  'movimentacao-lote': 'Movimentação de Lote',
  'movimentacao-confinamento': 'Movimentação de Confinamento',
  'transferencia-propriedade': 'Transferência de Propriedade',
  perda: 'Perda',
  morte: 'Morte',
  venda: 'Venda',
  secagem: 'Secagem'
}

export type ResponseManejosData = {
  hashsAnimais: string[]
  animais: IAnimal[]
  lotes: ILote[]
  areas: IArea[]
  geraisQtd: number
  gerais: IManejoGeralUnitario[]
  genetica: IManejoGeneticaUnitario[]
  manejosLotesQtd: number
  manejosLotes: IManejoLote[]
  manejosAreasQtd: number
  manejosAreas: ManejoArea[]
  pesagensQtd: number
  nutricional: ManejoNutricional[]
  pesagens: ManejoPesagem[]
  reprodutivosQtd: number
  reprodutivos: ManejoReprodutivoUnitario[]
  sanitariosQtd: number
  sanitarios: ManejoSanitarioUnitario[]
  ordenhasQtd: number
  ordenhas: ManejoOrdenha[]
  associados: {
    animaisInternos: IAnimal[]
    animaisExternos: IReprodutorExterno[]
    estoquesBiologicos: IEstoqueBiologico[]
    estoqueNutricional: EstoqueNutricional[]
    estoqueSanitario: EstoqueFarmacia[]
    areas: IArea[]
    lotes: ILote[]
    piquetesHashs: string[]
    piquetes: IAreaPiquete[]
    pessoasHashs: string[]
    pessoas: Pessoa[]
  }
  sanitarioEstoque: ManejoSanitarioEstoque[]
  sanitarioEstoqueQtd: number
}

export type AprovacaoHashsItens = {
  hashsItens: string[]
}

type ResponseManejosReprodutivos = {
  manejos: ListaManejoReprodutivo[]
}

type IEstoqueBiologico = {
  hash: string
  touro?: string
}

type ResponseManejosSanitarios = {
  manejos: ManejoSanitario[]
}

type ResponseManejosPastejoRotacionado = {
  manejos: IManejoAreaPastejoRotacionado[],
  totais: { total: number }
}

@Injectable({
  providedIn: 'root'
})
export class ManejosService {
  public campoManejoAlterado = false
  public campoManejoLotesAlterado = false
  public campoManejoAreasAlterado = false
  constructor(private apiCtrl: ApiService, private permissoesCtrl: PermissoesUsuarioService) { }

  async getExposicoes(params?: QueryParamsmModel): Promise<[ResponseExposicoesData, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/reprodutivos/exposicoes',
        queryParams: params
      })) as ResponseExposicoesData

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getManejosData(data: string, params?: QueryParamsmModel): Promise<[ResponseManejosData, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: `manejos/${data}`,
        queryParams: params
      })) as ResponseManejosData

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getManejosReprodutivos(params?: QueryParamsmModel): Promise<[ResponseManejosReprodutivos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/reprodutivos',
        queryParams: params
      })) as ResponseManejosReprodutivos

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getManejosReprodutivosPorAnimal(hash: string): Promise<[ResponseManejosReprodutivos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/reprodutivos',
        queryParams: { hash_animal: hash }
      })) as ResponseManejosReprodutivos

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getManejosSanitarios(params?: QueryParamsmModel): Promise<[ResponseManejosSanitarios, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/sanitarios',
        queryParams: params
      })) as ResponseManejosSanitarios

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getManejosSanitariosPorAnimal(hash: string): Promise<[ResponseManejosSanitarios, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/sanitarios',
        queryParams: { hash_animal: hash }
      })) as ResponseManejosSanitarios
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getTodosManejos(params?: NovoQueryParamsModel): Promise<[TodosManejos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos',
        filtros: params
      })) as TodosManejos
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getTodosManejosGerais(hash: string): Promise<[TodosManejos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/gerais',
        queryParams: { hash_animal: hash }
      })) as TodosManejos
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async getGenealogiaParto(data: string, hashsAnimais: string[]): Promise<[IGenealogiaPartoAgrupadosPorHash, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const genealogiaParto = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/reprodutivos/genealogia-parto',
        body: {
          data,
          hashsAnimais
        }
      })) as IRetornoGenealogiaParto

      const genealogiaPartoComPaisAgrupados: IGenealogiaPartoAgrupadosPorHash =
        this.getGenealogiaPartoComPaisAgrupados(genealogiaParto)

      return [genealogiaPartoComPaisAgrupados]
    } catch (error) {
      return [null, error]
    }
  }

  async enviarHashsManejosMudancaData(payload: {
    hashs: {
      gerais: string[]
      manejosLotes: string[]
      manejosAreas: string[]
      nutricional: string[]
      ordenhas: string[]
      pesagens: string[]
      reprodutivos: string[]
      sanitarios: string[]
    }
    data: string
  }): Promise<[boolean, Error?]> {
    try {
      const response = await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/mudar-data',
        body: payload
      })

      if (response) return [true]
    } catch (error) {
      return [null, error]
    }
  }

  private getGenealogiaPartoComPaisAgrupados(
    retornoGenealogiaParto: IRetornoGenealogiaParto
  ): IGenealogiaPartoAgrupadosPorHash {
    const genealogiaPartoComPaisAgrupados: IGenealogiaPartoAgrupadosPorHash = {}
    const hashFemeas = Object.keys(retornoGenealogiaParto.genealogias)

    for (const hashFemea of hashFemeas) {
      const hashMaeInterna = retornoGenealogiaParto.genealogias[hashFemea].maeInternaHash
      const hashMaeExterna = retornoGenealogiaParto.genealogias[hashFemea].maeExternaHash

      const hashPaiInterno = retornoGenealogiaParto.genealogias[hashFemea].paiInternoHash
      const hashPaiExterno = retornoGenealogiaParto.genealogias[hashFemea].paiExternoHash

      const genealogia: IGenealogiaPaisAgrupado = {
        paiInterno: retornoGenealogiaParto.animaisInternos.find((animal) => animal.hash === hashPaiInterno),
        paiExterno: retornoGenealogiaParto.animaisExternos.find((animal) => animal.hash === hashPaiExterno),
        maeExterna: retornoGenealogiaParto.animaisExternos.find((animal) => animal.hash === hashMaeExterna),
        maeInterna: retornoGenealogiaParto.animaisInternos.find((animal) => animal.hash === hashMaeInterna)
      }

      genealogia.nomeMae =
        genealogia?.maeExterna?.nome ||
        genealogia.maeExterna?.numeracao ||
        genealogia.maeInterna?.nome ||
        genealogia.maeInterna?.numeracao
      genealogia.nomePai =
        genealogia?.paiExterno?.nome ||
        genealogia.paiExterno?.numeracao ||
        genealogia.paiInterno?.nome ||
        genealogia.paiInterno?.numeracao

      genealogiaPartoComPaisAgrupados[hashFemea] = genealogia
    }

    return genealogiaPartoComPaisAgrupados
  }

  async getManejosPastejoRotacionado(params?: NovoQueryParamsModel): Promise<[ResponseManejosPastejoRotacionado, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const response = (await this.apiCtrl.getComPrefixoPropriedade({
        url: 'manejos/areas/pastejo-rotacionado',
        filtros: params
      })) as ResponseManejosPastejoRotacionado
      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

  async getListaTodosManejos(params?: NovoQueryParamsModel): Promise<[ResponseListaTodosManejos, Error?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'leitura')
      const filtros = this.apiCtrl.formatarFiltros(params)
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/listar-tudo',
        body: {
          filtros
        }
      })) as ResponseListaTodosManejos
      if (response) {
        return [response]
      }
    } catch (error) {
      return [null, error]
    }
  }

  async deleteManejo(dados: IListaTodosManejos[]): Promise<[StatusRequest, ApiError?]> {
    try {
      this.permissoesCtrl.verificaPermissaoDeRota('manejos', 'escrita')
      const response = (await this.apiCtrl.postComPrefixoPropriedade({
        url: 'manejos/remover-manejos',
        body: {
          dados
        }
      })) as StatusRequest

      if (response) return [response]
    } catch (error) {
      return [null, error]
    }
  }

}
