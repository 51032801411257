<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>
      <propriedade-nome></propriedade-nome>
      Selecionar Estoque de Sêmen e Embriões
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close" color="primary" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <vasta-tabela
    [labels]="labelsEstoqueBiologico"
    [campos]="estoqueBiologico"
    [config]="configEstoqueBiologico"
    [(novaOrdenacao)]="novaOrdenacao"
    [status]="carregamentoEstoqueBiologico"
    (selecionado)="selecionarSemenOuEmbriao($event)"
  ></vasta-tabela>
</ion-content>
