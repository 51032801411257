import { ApplicationRef, Component, OnInit, ViewEncapsulation } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { EstoqueService } from 'src/app/services/estoque.service'
import { IonicUtilsService } from 'src/app/services/utils/ionic-utils.service'
import { EstoqueNutricional, IEstoque, IEstoqueNutricionalPayload } from 'src/app/utils/interfaces/estoque.interface'
import { ModalEstoqueNutricionalComponent } from '../modal-estoque-nutricional/modal-estoque-nutricional.component'
import moment from 'moment'
import { Router } from '@angular/router'
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'app-estoque-nutricional-cadastrar',
  templateUrl: './estoque-nutricional-cadastrar.page.html',
  styleUrls: ['./estoque-nutricional-cadastrar.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EstoqueNutricionalCadastrarPage implements OnInit {
  public itemProp: EstoqueNutricional & IEstoque
  salvando = false
  item: IEstoqueNutricionalPayload = {
    hash: '',
    titulo: '',
    descricao: '',
    tipo: 'nutricional',
    metadados: {
      tipo: 'racao',
    },
    formulacao: []
  }

  abaAtiva: 'Geral' | 'Formulação' = 'Geral'
  
  insumos = []
  insumosTotais: {[key: string]: number} = {}
  percentualTotal = 0

  constructor(
    private modalCtrl: ModalController,
    private estoqueCtrl: EstoqueService,
    private router: Router,
    private utilsCtrl: IonicUtilsService,
    private ref: ApplicationRef
  ) {}

  ngOnInit(): void {
    console.log('itemProp', this.itemProp)
    if (this.itemProp) {
      const { hash, tipo, titulo, meta_tipoDoEstoque } = this.itemProp

      this.item = {
        hash,
        titulo,
        descricao: '',
        tipo: 'nutricional',
        metadados: {
          tipo: meta_tipoDoEstoque
        }
      }
    }
    
    if(this.itemProp?.formulacao){
      this.insumos = this.itemProp.formulacao || []
      this.insumos.forEach(insumo => {
        this.insumosTotais[insumo.hash] = insumo.percentual
        this.calcularTotais()
      })
    }
  }

  closeModal(): void {
    this.modalCtrl.dismiss()
  }

  async salvar(): Promise<void> {
    this.salvando = true

    const itensFormulacao = []
    for( const key in this.insumosTotais ) {
      let itemFormulacao = {}
      itemFormulacao['hash_item_nutricional'] = key
      itemFormulacao['percentual'] = this.insumosTotais[key]
      itensFormulacao.push(itemFormulacao)
    }

    this.item['formulacao'] = itensFormulacao

    const [response, error] = await this.estoqueCtrl.setEstoqueNutricional({ item: this.item })

    if (response) {
      const buttons = []

      if (!this.itemProp?.hash) {
        buttons.push({
          text: 'Abrir',
          handler: async (): Promise<void> => {
            const hash = response.hashs[0]
            const url = `/estoque/nutricional/${encodeURIComponent(hash)}`

            if (Capacitor.getPlatform() === 'web') {
              window.open(url, '_blank')
            } else {
              const [resposta] = await this.estoqueCtrl.getEstoqueNutricional({ hash })

              if (resposta) {
                const item = resposta.estoques[0]
                this.router.navigateByUrl(url, {
                  state: {
                    item
                  }
                })
              }
            }
          }
        })
      }

      const { hashs } = response

      this.salvando = false
      this.modalCtrl.dismiss({ success: true, hashs })
      this.utilsCtrl.newToast({
        message: this.itemProp?.hash ? 'Item atualizado com sucesso' : 'Item salvo com sucesso',
        duration: this.itemProp?.hash ? 3000 : 5000,
        buttons
      })
    }

    if (error) {
      this.salvando = false
      this.utilsCtrl.showToast('Erro ao atualizar item. Tente Novamente!', 'bottom')
      console.log(error)
    }
  }

  tick(): void {
    this.ref.tick()
  }

  get isCampoObrigatoriosValidos(): boolean {
    const isValidos = this.item.titulo
    return !!isValidos
  }

  changeTab(valor: string): void {
    this.abaAtiva = valor as 'Geral' | 'Formulação'
  }

  async adicionarItemNutricional(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: ModalEstoqueNutricionalComponent,
      componentProps: {
        data_custo: moment().format('YYYY-MM-DD'),
        hash_not_in: [this.item.hash, ...(this.insumos.map(a => a.hash))],
        meta_tipo_do_estoque_not_in: 'racao'
      },
      cssClass: 'custom-modal-animais'
    })

    await modal.present()
    const { data } = await modal.onWillDismiss()
    if (data) {
      console.log({ data })
      if (data.itensSelecionados?.length) {
        this.insumos = [...this.insumos, ...data.itensSelecionados]
      } else {
        this.insumos.push(data)
      }
      console.log('this.insumos', this.insumos)
    }
  }

  calcularTotais(): void {
    let percentualTotal = 0

    this.insumos.map(insumo => {
      if (this.insumosTotais[insumo.hash]) {
        percentualTotal += this.insumosTotais[insumo.hash]
      }
    })

    this.percentualTotal = percentualTotal
  }

  async removeInsumo(ev: Event, hash: string): Promise<void> {
    const confirma = await this.utilsCtrl.popoverConfirma(ev)
    
    if (confirma) {
      this.insumos = this.insumos.filter(insumo => insumo.hash !== hash)
      delete this.insumosTotais[hash]
      this.calcularTotais()
    }
  }

  limparFormulacao(): void {
    this.insumos = []
    this.insumosTotais = {}
    this.percentualTotal = 0
  }
}
